(function() {
	'use strict';
	angular.module('eventos').controller('loginCtrl', loginCtrl);

	function loginCtrl($http, $scope) {
		const vm = this;
		vm.data = {};
		vm.show = false;
		vm.login = () => {
			$http.post('/api/profile', vm.data).then(r => {
				window.location = `/${r.data.url}`;
			}, err => {
				if (err.status === 401) {
					vm.show = true;
					setTimeout(() => {
						vm.show = false;
						$scope.$apply();
					}, 2500);
				}
			});
		}
	}
	loginCtrl.$inject = ['$http', '$scope'];
})();
